



















































import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'
import { number, string } from 'yargs'

@Component({
    components: {
        Modal,
    },
})
export default class VerifyMnemonic extends Vue {
    isActive: boolean = false
    keysIn: string[] = []
    hiddenIndices: number[] = []
    err: string = ''
    title: string = ''
    missingWords: string[] = []
    draggedData: string = ''
    isDropped: boolean = false

    @Prop() mnemonic?: MnemonicPhrase
    @Prop({ default: 6 }) rowSize!: number

    @Watch('mnemonic')
    onmnemonicchange(val: string) {
        this.init()
    }

    onDrop(event: any) {
        let id = event.target.id
        let previous = this.keysIn[id]
        event.target.value = this.draggedData
        this.keysIn[id] = this.draggedData
        if (previous == '') {
            this.isDropped = true
        } else {
            let index = this.missingWords.findIndex((element) => element == previous)
            let element = document.getElementById('sugg' + index.toString()) as HTMLInputElement
            element.classList.remove('hidebuts')
            this.isDropped = true
        }
    }

    onDragEnd(event: any) {
        if (this.isDropped) {
            event.target.classList.add('hidebuts')
            this.isDropped = false
        }
    }

    created() {
        this.init()
        this.title = `${this.$t('create.verifytitle')}`
    }

    showSuggestions() {
        this.hiddenIndices.map((x) => {
            let word = document.getElementById(x.toString()) as HTMLInputElement
            word.value = ''
            this.keysIn[x] = ''
        })
        let element = document.getElementsByClassName('suggestionbuts')
        for (let i = 0; i <= element.length; i++) {
            element[i].classList.remove('hidebuts')
        }
    }

    init() {
        const wordsLen = 24
        this.keysIn = Array(wordsLen).join('.').split('.')

        // Hide 4 words
        let hideNum = 6
        let hidden: number[] = []

        while (hidden.length < hideNum) {
            let hideIndex = Math.floor(Math.random() * wordsLen)
            if (!hidden.includes(hideIndex)) {
                hidden.push(hideIndex)
            }
        }

        this.words.forEach((word, i) => {
            if (!hidden.includes(i)) {
                this.keysIn[i] = word
            }
        })
        this.hiddenIndices = hidden
        this.hiddenIndices.map((x: number) => this.missingWords.push(this.words[x]))
    }

    get words() {
        return this.mnemonic ? this.mnemonic.getValue().split(' ') : []
    }

    open() {
        // @ts-ignore
        this.$refs.modal.open()
    }

    close() {
        this.isActive = false
    }

    formCheck() {
        this.err = ''
        let userWords = this.keysIn

        for (var i = 0; i < userWords.length; i++) {
            let userWord = userWords[i].trim()
            let trueWord = this.words[i].trim()
            if (userWord.length === 0) {
                this.err = `Oops, looks like you forgot to fill number ${i + 1}`
                return false
            }

            if (userWord !== trueWord) {
                this.err = `The mnemonic phrase you entered for word ${
                    i + 1
                } not match the actual phrase.`
                return false
            }
        }

        return true
    }

    verify() {
        if (!this.formCheck()) return
        // @ts-ignore
        this.$emit('complete')
    }
}
