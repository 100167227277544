










































































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ImportKeyfileInput } from '@/store/types'

import Modal from '@/components/modals/Modal.vue'
import Identicon from '../misc/Identicon.vue'
import { verifyAccountPassword } from '@/helpers/account_helper'

@Component({
    components: {
        Modal,
        Identicon,
    },
})
export default class Authentication extends Vue {
    password: string = ''
    isPasswordVisible: boolean = false
    isLoading: boolean = false
    error: string = ''
    inputType: string = 'password'
    typeOfModal: any
    open(type: any): void {
        let modal = this.$refs.modal as Modal
        this.typeOfModal = type
        modal.open()
    }
    close() {
        let modal = this.$refs.modal as Modal
        this.password = ''
        // @ts-ignore
        modal.close()
    }
    beforeClose() {
        this.password = ''
        this.error = ''
    }
    togglePasswordVisibility() {
        this.isPasswordVisible = !this.isPasswordVisible
        if (this.inputType === 'password') this.inputType = 'text'
        else this.inputType = 'password'
    }

    async access() {
        await this.$store
            .dispatch('Accounts/confirmAccountPassword', this.password)
            .then((res) => {
                this.$emit('verified', this.typeOfModal)
                this.close()
            })
            .catch((err) => {
                console.log('error')
                this.error = err
            })
    }
}
