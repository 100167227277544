













import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import Modal from './Modal.vue'
import CopyText from '../misc/CopyText.vue'
import QRCode from 'qrcode'
import { KeyPair as DVMKeyPair } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
// import { LedgerWallet } from '@/js/wallets/LedgerWallet'

@Component({
    components: {
        Modal,
        CopyText,
    },
})
export default class QRModal extends Vue {
    colorDark: string = '#242729'
    colorLight: string = '#FFF'

    @Prop({ default: '-' }) address!: string

    @Watch('address', { immediate: true })
    onaddrchange(val: string) {
        if (val) {
            this.updateQR()
        }
    }

    @Watch('$root.theme', { immediate: true })
    onthemechange(val: string) {
        if (val === 'night') {
            this.colorDark = '#fff'
            this.colorLight = '#0f014680'
        } else {
            this.colorDark = '#242729'
            this.colorLight = '#ffffff80'
        }
        this.updateQR()
    }

    open() {
        // @ts-ignore
        this.$refs.modal.open()

        Vue.nextTick(() => {
            this.updateQR()
        })
    }
    updateQR() {
        if (!this.address) return
        let canvas = this.$refs.qr
        QRCode.toCanvas(
            canvas,
            this.address,
            {
                scale: 6,
                color: {
                    light: this.colorLight,
                    dark: this.colorDark,
                },
            },
            function (error) {
                if (error) console.error(error)
            }
        )
    }
}
