














import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import CopyText from '../misc/CopyText.vue'

@Component({
    components: {
        Modal,
        CopyText,
    },
})
export default class PrivateKey extends Vue {
    @Prop({ default: '' }) privateKey!: string

    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()
    }
}
