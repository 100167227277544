
























import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmountOutput, DVMConstants, UTXO as DVMUTXO } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import {
    AuthorityVMConstants,
    StakeableLockOut,
    UTXO as AuthorityUTXO,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import { dcomm, bintools } from '@/DCOMM'
import DcommAsset from '@/js/DcommAsset'
import { bnToBig } from '@/helpers/helper'
import { UnixNow } from '@dcomm-tech/dcomm-js/dist/utils'
import { DcommNetwork } from '@/js/DcommNetwork'

@Component
export default class UTXORow extends Vue {
    @Prop() utxo!: DVMUTXO | AuthorityUTXO
    @Prop({ default: true }) isX!: boolean

    get out() {
        return this.utxo.getOutput()
    }

    get typeID(): number {
        return this.out.getTypeID()
    }

    get addresses(): string[] {
        let addrs = this.out.getAddresses()

        let hrp = dcomm.getHRP()
        let id = this.isX ? 'AST' : 'ATH'
        let addrsClean = addrs.map((addr) => {
            return bintools.addressToString(hrp, id, addr)
        })
        return addrsClean
    }
    // get typeName() {
    //     return this.utxo.getTypeID()
    // }
    get asset() {
        // if(this.typeID)
        let assetID = this.utxo.getAssetID()
        let idClean = bintools.cb58Encode(assetID)

        let asset =
            this.$store.state.Assets.assetsDict[idClean] ||
            this.$store.state.Assets.nftFamsDict[idClean]
        return asset
    }

    get explorerLink() {
        let net: DcommNetwork = this.$store.state.Network.selectedNetwork
        let explorer = net.explorerSiteUrl
        let currentNet = this.$store.state.Network.selectedNetwork.name.toLowerCase()
        if (!explorer) return null
        return (
            explorer + '/tx/' + bintools.cb58Encode(this.utxo.getTxID()) + `network=${currentNet}`
        )
    }

    get locktime() {
        let locktime = this.out.getLocktime().toNumber()
        if (!this.isX && this.typeID === AuthorityVMConstants.STAKEABLELOCKOUTID) {
            let stakeableLocktime = (this.out as StakeableLockOut).getStakeableLocktime().toNumber()
            locktime = Math.max(locktime, stakeableLocktime)
        }
        return locktime
    }
    get locktimeText() {
        let now = UnixNow().toNumber()
        let locktime = this.locktime

        if (now >= locktime) {
            return '-'
        } else {
            let date = new Date(locktime * 1000)
            return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
        }
    }

    get symbol() {
        if (!this.asset) return '-'
        return this.asset.symbol
    }

    get balanceText() {
        if (!this.asset) return '-'

        if (this.typeID === 7 || this.typeID === AuthorityVMConstants.STAKEABLELOCKOUTID) {
            let out = this.out as AmountOutput
            let denom = (this.asset as DcommAsset).denomination
            let bn = out.getAmount()
            return bnToBig(bn, denom).toLocaleString()
        }

        if ([6, 7, 10, 11].includes(this.typeID)) {
            return 1
        }

        return '-'
    }

    get typeName(): string {
        AuthorityVMConstants
        switch (this.typeID) {
            case DVMConstants.SECPMINTOUTPUTID:
                return 'SECP Mint Output'
            case DVMConstants.SECPXFEROUTPUTID:
                return 'SECP Transfer Output'
            case DVMConstants.NFTMINTOUTPUTID:
                return 'NFT Mint Output'
            case DVMConstants.NFTXFEROUTPUTID:
                return 'NFT Transfer Output'
            case AuthorityVMConstants.STAKEABLELOCKOUTID:
                return 'Stakeable Lock Output'
        }
        return ''
    }
}
