






















import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import { CsvRowDcmTransferData, ITransactionData, UTXO } from '@/store/modules/history/types'
import { bnToBig } from '@/helpers/helper'
const generate = require('csv-generate')
import {
    dcmTransferDataToCsvRow,
    getOutputTotals,
    getOwnedOutputs,
    getNotOwnedOutputs,
    getAssetOutputs,
    getAddresses,
    createCSVContent,
    downloadCSVFile,
    parseMemo,
} from '@/store/modules/history/history_utils'
import { createCsvNormal, getHistoryForOwnedAddresses } from '@dcomm-tech/wallet-sdk'
import { dcomm, dvm } from '@/DCOMM'
import { BN } from '@dcomm-tech/dcomm-js'

@Component({
    components: {
        Modal,
    },
})
export default class ExportDcmCsvModal extends Vue {
    error: Error | null = null
    isLoading = false

    open(): void {
        this.error = null
        let modal = this.$refs.modal as Modal
        modal.open()
    }

    get canSubmit() {
        return true
    }

    get transactions(): ITransactionData[] {
        return this.$store.state.History.allTransactions
    }

    get wallet() {
        return this.$store.state.activeWallet
    }

    get xAddresses(): string[] {
        return this.wallet.getAllAddressesX()
    }

    get xAddressesStripped(): string[] {
        return this.xAddresses.map((addr: string) => addr.split('-')[1])
    }

    get dcmID() {
        return this.$store.state.Assets.DCOMM_ASSET_ID
    }

    async generateCSVFile() {
        this.isLoading = true

        try {
            const hist = await getHistoryForOwnedAddresses(
                this.wallet.getAllAddressesX(),
                this.wallet.getAllAddressesP(),
                this.wallet.getEvmAddressBech(),
                this.wallet.getEvmAddress()
            )

            const encoding = 'data:text/csv;charset=utf-8,'
            const csvContent = createCsvNormal(hist)
            downloadCSVFile(encoding + csvContent, 'dcm_transfers')
        } catch (e) {
            this.error = e
        }
        this.isLoading = false
    }

    submit() {
        try {
            this.error = null
            this.generateCSVFile()
        } catch (e) {
            this.error = e
        }
    }
}
