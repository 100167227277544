

















































import { Vue, Component } from 'vue-property-decorator'
import Modal from '@/components/modals/Modal.vue'
import { WalletType } from '@/js/wallets/types'

import {
    UTXOSet as DVMUTXOSet,
    UTXO as DVMUTXO,
    DVMConstants,
} from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import {
    UTXOSet as AuthorityUTXOSet,
    UTXO as AuthorityUTXO,
    AuthorityVMConstants,
    StakeableLockOut,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import UTXORow from '@/components/modals/UtxosBreakdown/DVMUTXORow.vue'

@Component({
    components: { UTXORow, Modal },
})
export default class UtxosBreakdownModal extends Vue {
    chain = 'AST'

    $refs!: {
        modal: Modal
    }
    open(): void {
        let modal = this.$refs.modal as Modal
        //@ts-ignore
        modal.open()
    }

    setChain(chainID: string) {
        this.chain = chainID
    }

    get wallet(): WalletType | null {
        return this.$store.state.activeWallet
    }

    get dvmUTXOs(): DVMUTXO[] {
        if (!this.wallet) return []
        let utxos = this.wallet.getUTXOSet().getAllUTXOs()
        let sorted = utxos.sort(this.sortFnc)
        return sorted
    }

    get authorityUTXOs(): AuthorityUTXO[] {
        if (!this.wallet) return []
        let utxos = this.wallet.getAuthorityUTXOSet().getAllUTXOs()
        let sorted = utxos.sort(this.sortFnc)
        return sorted
    }

    get isEmpty() {
        if (this.chain === 'AST') {
            return this.dvmUTXOs.length === 0
        } else {
            return this.authorityUTXOs.length === 0
        }
    }

    sortFnc<UTXO extends DVMUTXO | AuthorityUTXO>(a: UTXO, b: UTXO) {
        let aOut = a.getOutput()
        let bOut = b.getOutput()

        let aType = aOut.getTypeID()
        let bType = bOut.getTypeID()

        if (aType === bType) {
            let aLock = aOut.getLocktime().toNumber()
            let bLock = bOut.getLocktime().toNumber()

            if (aType === AuthorityVMConstants.STAKEABLELOCKOUTID) {
                let aStakeLock = (aOut as StakeableLockOut).getStakeableLocktime().toNumber()
                let bStakeLock = (bOut as StakeableLockOut).getStakeableLocktime().toNumber()

                aLock = Math.max(aLock, aStakeLock)
                bLock = Math.max(bLock, bStakeLock)
            }

            if (aLock !== bLock) return bLock - aLock
            return 0
        } else {
            if (aType === DVMConstants.SECPXFEROUTPUTID) {
                return -1
            } else if (bType === DVMConstants.SECPXFEROUTPUTID) {
                return 1
            }

            if (aType === DVMConstants.NFTXFEROUTPUTID) {
                return -1
            } else if (bType === DVMConstants.NFTXFEROUTPUTID) {
                return 1
            }

            if (aType === DVMConstants.NFTMINTOUTPUTID) {
                return -1
            } else if (bType === DVMConstants.NFTMINTOUTPUTID) {
                return 1
            }

            if (aType === DVMConstants.SECPMINTOUTPUTID) {
                return -1
            } else if (bType === DVMConstants.SECPMINTOUTPUTID) {
                return 1
            }

            // if(aType === DVMConstants.)
        }

        return 0
    }
}
