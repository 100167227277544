























import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import MnemonicDisplay from '@/components/misc/MnemonicDisplay.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'

@Component({
    components: {
        Modal,
        MnemonicDisplay,
    },
})
export default class MnemonicPhraseModal extends Vue {
    @Prop({ default: '' }) phrase!: MnemonicPhrase

    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()
    }
    copy() {
        navigator.clipboard.writeText(this.phrase?.getValue() || '').then(() => {
            this.$store.dispatch('Notifications/add', {
                title: 'Copy',
                message: 'Copied to clipboard.',
            })
        })
    }
}
