













import { Vue, Component, Prop } from 'vue-property-decorator'
import { WalletType } from '@/js/wallets/types'

// import { LedgerWallet } from '@/js/wallets/LedgerWallet'
import { dcomm } from '@/DCOMM'
import { getPreferredHRP } from '@dcomm-tech/dcomm-js/dist/utils'
import { DCOMM_ACCOUNT_PATH } from '@/js/wallets/MnemonicWallet'

@Component
export default class HdEmptyAddressRow extends Vue {
    @Prop() index!: number
    @Prop() path!: number
    @Prop() address!: string

    get wallet() {
        return this.$store.state.activeWallet as WalletType
    }

    get walletType() {
        return this.wallet.type
    }

    // async verifyLedgerAddress() {
    //     const wallet = this.wallet as LedgerWallet

    //     let networkId = dcomm.getNetworkID()
    //     let hrp = getPreferredHRP(networkId)

    //     wallet.app.getWalletAddress(`${DCOMM_ACCOUNT_PATH}/${this.path}/${this.index}`, hrp)
    // }
}
