







































import 'reflect-metadata'
import { Vue, Component, Prop, Model } from 'vue-property-decorator'

import Modal from '@/components/modals/Modal.vue'
import {
    AmountOutput,
    AuthorityVMConstants,
    SECPTransferOutput,
    StakeableLockOut,
    UTXO,
    UTXOSet,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'

import UtxoRow from '@/components/modals/UtxoSelect/UtxoRow.vue'
import { BN } from '@dcomm-tech/dcomm-js'
import { UnixNow } from '@dcomm-tech/dcomm-js/dist/utils'
import { bnToBig } from '@/helpers/helper'

@Component({
    components: {
        Modal,
        UtxoRow,
    },
})
export default class UtxoSelect extends Vue {
    @Model('change', { type: Array }) readonly utxos!: UTXO[]
    @Prop() all!: UTXO[]

    customSet = new UTXOSet()

    addUtxo(utxo: UTXO) {
        this.customSet.add(utxo)
        this.$emit('change', this.customSet.getAllUTXOs())
    }

    removeUtxo(utxo: UTXO) {
        this.customSet.remove(utxo)
        this.$emit('change', this.customSet.getAllUTXOs())
    }
    open(): void {
        let modal = this.$refs.modal as Modal
        modal.open()
        this.customSet = new UTXOSet()
        this.$emit('change', this.customSet.getAllUTXOs())
    }

    close(): void {
        let modal = this.$refs.modal as Modal
        modal.close()
    }

    get allSorted() {
        return this.all.sort((a: UTXO, b: UTXO) => {
            // Sort by Lock status
            let typeA = a.getOutput().getTypeID()
            let typeB = b.getOutput().getTypeID()

            let locktimeA = a.getOutput().getLocktime()
            let locktimeB = a.getOutput().getLocktime()

            if (typeA === AuthorityVMConstants.STAKEABLELOCKOUTID) {
                let sLocktime = (a.getOutput() as StakeableLockOut).getStakeableLocktime()
                locktimeA = BN.max(locktimeA, sLocktime)
            }

            if (typeB === AuthorityVMConstants.STAKEABLELOCKOUTID) {
                let sLocktime = (b.getOutput() as StakeableLockOut).getStakeableLocktime()
                locktimeB = BN.max(locktimeB, sLocktime)
            }

            let now = UnixNow()

            // if (now.lt(locktimeA) && now.lt(locktimeB)) {
            if (locktimeA.gt(locktimeB)) {
                return -1
            } else if (locktimeA.lt(locktimeB)) {
                return 1
            }
            // }

            // Sort by amount
            let outA = a.getOutput() as StakeableLockOut | SECPTransferOutput
            let outB = b.getOutput() as StakeableLockOut | SECPTransferOutput

            let amtA = outA.getAmount()
            let amtB = outB.getAmount()

            if (amtA.gt(amtB)) {
                return -1
            } else {
                return 1
            }

            return 0
        })
    }

    get selectedBalance() {
        let res = this.utxos.reduce((acc, utxo) => {
            let out = utxo.getOutput() as AmountOutput | StakeableLockOut
            return acc.add(out.getAmount())
        }, new BN(0))
        return res
    }

    get selectedBalanceText() {
        return bnToBig(this.selectedBalance, 9).toLocaleString()
    }
}
