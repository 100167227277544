




































import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { KeyPair as DVMKeyPair, UTXOSet as DVMUTXOSet } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import { UTXOSet as AuthorityUTXOSet } from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import { dcomm, bintools } from '@/DCOMM'
import Big from 'big.js'
import DcommAsset from '@/js/DcommAsset'
import { DerivationListBalanceDict } from '@/components/modals/HdDerivationList/types'
// import { LedgerWallet } from '../../../js/wallets/LedgerWallet'
import { bnToBig } from '@/helpers/helper'
import { BN } from '@dcomm-tech/dcomm-js'
import HdChainTable from '@/components/modals/HdDerivationList/HdChainTable.vue'

@Component({
    components: {
        HdChainTable,
    },
})
export default class HDDerivationList extends Vue {
    @Prop() wallet!: MnemonicWallet

    addrsExternal: string[] = []
    addrsInternal: string[] = []
    addrsAuthority: string[] = []

    @Watch('wallet.internalHelper.utxoSet', { immediate: true })
    onInternalUtxoChange() {
        this.addrsInternal = this.wallet.internalHelper.getAllDerivedAddresses()
    }

    @Watch('wallet.externalHelper.utxoSet', { immediate: true })
    onExternalUtxoChange() {
        this.addrsExternal = this.wallet.externalHelper.getAllDerivedAddresses()
    }

    @Watch('wallet.authorityHelper.utxoSet', { immediate: true })
    onAuthorityUtxoChange() {
        this.addrsAuthority = this.wallet.authorityHelper.getAllDerivedAddresses()
    }

    get internalHelper() {
        return this.wallet.internalHelper
    }
    get externalHelper() {
        return this.wallet.externalHelper
    }
    get authorityHelper() {
        return this.wallet.authorityHelper
    }

    get assetsDict() {
        return this.$store.state.Assets.assetsDict
    }

    utxoSetToBalanceDict(
        set: DVMUTXOSet | AuthorityUTXOSet,
        addrs: string[]
    ): DerivationListBalanceDict[] {
        let assets: DcommAsset[] = this.$store.state.Assets.assets

        let denoms: number[] = assets.map((asset) => {
            return asset.denomination
        })
        let assetIds: string[] = this.$store.getters['Assets/assetIds']

        let res = []
        for (var i = 0; i < addrs.length; i++) {
            let balDict: DerivationListBalanceDict = {}
            let addrBuff = bintools.stringToAddress(addrs[i])
            assetIds.forEach((assetId, index) => {
                let bal: BN = set.getBalance([addrBuff], assetId)

                if (!bal.isZero()) {
                    let balBig = bnToBig(bal, denoms[index])
                    balDict[assetId] = balBig
                }
            })
            res.push(balDict)
        }
        return res
    }

    get keyBalancesExternal(): DerivationListBalanceDict[] {
        let wallet = this.wallet
        let utxoSet = wallet.externalHelper.utxoSet as DVMUTXOSet
        let addrs = this.addrsExternal

        return this.utxoSetToBalanceDict(utxoSet, addrs)
    }

    get keyBalancesInternal(): DerivationListBalanceDict[] {
        let wallet = this.wallet
        let utxoSet = wallet.internalHelper.utxoSet
        let addrs = this.addrsInternal
        return this.utxoSetToBalanceDict(utxoSet, addrs)
    }

    get keyBalancesAuthority(): DerivationListBalanceDict[] {
        let wallet = this.wallet
        let utxoSet = wallet.authorityHelper.utxoSet
        let addrs = this.addrsAuthority
        return this.utxoSetToBalanceDict(utxoSet, addrs)
    }
}
